import {announceFromElement} from '@github-ui/aria-live'
import {on} from 'delegated-events'

function copyInput(inputToCopy: HTMLInputElement): HTMLInputElement {
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', inputToCopy.name)
  input.setAttribute('value', inputToCopy.value)
  return input
}

// Add the checked alerts to all the bulk action forms
function copyCheckedAlertsToBulkActionForms(container: Element) {
  let disableReopenButton = false
  const fixedAlertNumbersElements = container.querySelectorAll<HTMLElement>('.js-scanning-fixed-alert-numbers')
  const fixedAlertNumbers: number[] =
    fixedAlertNumbersElements.length !== 0
      ? JSON.parse(fixedAlertNumbersElements[0]!.getAttribute('data-numbers') || '[]')
      : []

  for (const items of container.querySelectorAll<HTMLElement>('.js-scanning-bulk-action-items')) {
    // Remove any previous alert elements from the form
    items.textContent = ''

    // Copy the checked items to the form
    const checkedAlerts = container.querySelectorAll<HTMLInputElement>(
      '.js-scanning-alert-list [data-check-all-item]:checked',
    )
    for (const checked of checkedAlerts) {
      // If any of the checked alerts are fixed, then disable the reopen button
      // as it only works for manually resolved alerts
      disableReopenButton ||= fixedAlertNumbers.indexOf(parseInt(checked.value)) !== -1
      items.appendChild(copyInput(checked))
    }
  }

  for (const reopenButton of container.querySelectorAll<HTMLButtonElement>('.js-scanning-reopen-button')) {
    reopenButton.hidden = disableReopenButton
  }
  for (const reopenButtonDisabled of container.querySelectorAll<HTMLButtonElement>(
    '.js-scanning-reopen-button-disabled',
  )) {
    reopenButtonDisabled.hidden = !disableReopenButton
  }
}

on('click', '.js-scanning-reopen-button-disabled', function (event: Event) {
  // Prevent the button from being clicked (we don't use disabled="true" because we want it to be focus-able)
  event.preventDefault()
})

on('change', '.js-scanning-alert-check', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-scanning-alert-list')!
  const checked = container.querySelector('.js-scanning-alert-check:checked') != null
  const filterOptions = container.querySelector<HTMLElement>('.js-scanning-filter-options')
  const bulkActions = container.querySelector<HTMLElement>('.js-scanning-alert-bulk-actions')
  const selectionStatus = bulkActions?.querySelector<HTMLElement>('[role=status]')
  const alertLinks = container.querySelector<HTMLElement>('.js-scanning-alert-links')

  if (filterOptions) {
    filterOptions.style.visibility = checked ? 'hidden' : 'visible'
  }
  if (bulkActions) {
    const bulkActionsChangingVisibility = bulkActions.hidden === checked
    bulkActions.hidden = !checked

    // Tell screenreaders that there's new status information available
    // (how many items are selected).
    // This is only necessary when the bulk actions UI is changing visibility as
    // otherwise the screenreader will notice the update to the live region that's
    // already visible. Note that VoiceOver on MacOS will announce the information
    // without this announceFromElement call, but
    // https://github.com/github/accessibility/blob/6aac4f4b3d25013274d39c2cdf1b4be43570eece/docs/coaching-recommendations/toast-flash-banner/accessible-banner-prototype.md#challenges-with-dynamically-inserted-live-region
    // suggests this shouldn't be relied on for screenreaders in general.
    if (bulkActionsChangingVisibility && selectionStatus) {
      // If the bulk actions are now hidden, it'll announce "0 selected", which is helpful clarification
      announceFromElement(selectionStatus)
    }
  }
  if (alertLinks) {
    alertLinks.style.visibility = checked ? 'hidden' : 'visible'
  }
  copyCheckedAlertsToBulkActionForms(container)
})

on(
  'click',
  '.js-scanning-code-path-menu-item',
  function (event) {
    if (event.currentTarget === null || !(event.currentTarget instanceof Element)) return
    const targetCodePath = event.currentTarget.getAttribute('data-target-code-path')
    for (const pathButton of document.querySelectorAll<HTMLElement>('.js-scanning-code-path-menu-item')) {
      pathButton.ariaChecked = 'false'
    }
    event.currentTarget.ariaChecked = 'true'
    for (const codePath of document.querySelectorAll<HTMLElement>('.js-scanning-code-path')) {
      codePath.hidden = targetCodePath !== codePath.getAttribute('data-code-path')
    }
  },
  {capture: true},
)
